import { useSearchParams } from '@remix-run/react'

type UseDialogReturn = [boolean, () => void, () => void]

export enum DialogIdentifier {
  SETTINGS = 'settings',
  CHANGE_PLACE = 'change-place',
  PIX = 'pix',
  HELP = 'need-help',
  HELP_PLACES = 'help-places',
  HELP_PLACE_DATA = 'help-place-data',
  HELP_ACCESS_DATA = 'help-access-data',
  HELP_LEGAL = 'help-legal',
  HELP_LOGIN = 'help-login',
  REGISTER = 'register',
  TYPEFORM = 'typeform',
  EMAIL = 'email',
  WHATSAPP = 'whatsapp',
  NO_CONTACT_ACCESS = 'no-contact-access',
  CANCELED = 'canceled',
  DISABLED = 'disabled',
  DISCO_MESSAGE = 'disco-message',
  NO_BILLED = 'no-billed',
  OTHER_VALUES = 'other-values',
  OTHER_COSTS = 'other-costs',
  ENERGY_FARM = 'energy-farm',
  ENERGY_FARM_BANNER = 'energy-farm-banner',
  PAYMENT = 'payment',
}

const useDialog = (dialogIdentifier: DialogIdentifier): UseDialogReturn => {
  const [searchParams, setSearchParams] = useSearchParams()

  const isOpen = searchParams.get('dialog') === dialogIdentifier

  const open = () => {
    setSearchParams(
      (prev) => {
        prev.set('dialog', dialogIdentifier)
        return prev
      },
      {
        replace: true,
      },
    )
  }

  const close = () => {
    setSearchParams(
      (prev) => {
        prev.delete('dialog')
        return prev
      },
      {
        replace: true,
      },
    )
  }

  return [isOpen, open, close]
}

export default useDialog
